.carouselContainer {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4vw;
    /* background-color: var(--main-background-color); */
}

.arrow {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--red-color);
    padding: 14px;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s ease;
    display: flex;
}

.smallArrow{
    padding: 14px;
}

.arrow:active {
    opacity: 0.7;
}

.arrowIcon {
    width: 26px;
    height: 26px;
    object-fit: contain;
    max-width: 26px;
}

.smallArrowIcon {
    width: 24px;
    height: 24px;
    max-width: 24px;
}

.leftArrow {
    left: 0;
    margin: 0 0 0 1vw;
}

.leftIcon {

}

.rightArrow {
    right: 0;
    margin: 0 1vw 0 0;
}

.rightIcon {
    transform: rotate(180deg);
}

.indicatorContainer{
    display: inline-block;
}

.indicator {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #fff;
    cursor: pointer;
    display: inline-block;
    margin:14px 1vw 8px;
}

.selectedIndicator{
    opacity: 0.3;
}

@media (max-width: 768px) {
    .arrow {
        padding: 14px;
        margin: 0;
    }

    .arrowIcon {
        width: 18px;
        height: 18px;
        max-width: 18px;
    }

    .indicator {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #fff;
        cursor: pointer;
        display: inline-block;
        margin:0 1vw 8px;
    }
}
