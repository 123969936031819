.helloworld {
  background-color: var(--background-color);
}

@media (max-width: 768px) {
  .helloworld {
    padding: 0;
    max-width: 100%;
  }

  .topSeparator {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 0;
  }

  .bookNowA {
    margin-right: 1rem;
    color: #fff;
    font-size: 14px;
  }
}
