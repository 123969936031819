.bookingForm {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.booking {
    display: flex;
    background-color: var(--background-color);
    flex-direction: column;
    padding: 8vh 8vw;
}

.booking h2 {
    color: var(--primary);
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}

.bookingForm label {
    font-size: clamp(1vw, 1.5vw, 2vw);
    margin-bottom: 0.5rem;
    justify-content: space-between;
    display: flex;
    padding: 0 20%;
    color: var(--primary);
    white-space: pre-wrap;
}

.bookingForm input, textarea {
    border-radius: 5px;
    padding: 1vh 1vw;
    font-size: clamp(0.5vw, 1vw, 1.5vw);
    width: 60%;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.bookingFormButton {
    border-radius: 5px;
    padding: 1.6vh 2.2vw;
    font-size: clamp(1vw, 1.5vw, 2vw);
    outline-width: 0;
    border-width: 1px;
    border-color: #282c34;
    color: #e0e9f5;
    max-width: 15vw;
    align-self: center;
    background-color: #317033;
    cursor: pointer;
    box-shadow: 0 8px #999;
    margin: 2vh 0;
}

.bookingFormButton:hover {
    background-color: darkgreen;

}

.bookingFormButton:active {
    background-color: darkgreen;
    box-shadow: 0 4px #666;
    transform: translateY(4px);
}

.link {
    flex-direction: row;
    display: flex;
    align-items: center;
    background-color: var(--primary);
    border-radius: 10px;
    padding: 0 1rem;
    width: 20vw;
}

.link:hover {
    color: var(--primary);
}

.link img {
    width: 3vw;
    height: 3vw;
    margin-right: 1rem;
}

.link h3 {
    font-size: 2rem;
}

.booknow {
    align-self: flex-start;
    margin: 5vh 0 0 0;
}

.bookingWrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.nailInfo {
    display: flex;
    flex-direction: column;
    background-color: var(--primary);
    padding: 1rem;
    border-radius: 10px;
}

.nailInfo a {
    color: var(--background-color);
}

.nailInfo h2 {
    color: var(--background-color)
}

.bookingInfoInput {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex: 1
}

.bookBtn {
    max-height: 3rem;
    margin: 2rem auto 0 auto;
}

@media (max-width: 768px) {
    .bookingForm label {
        font-size: clamp(0.8rem, 3.2vw, 3rem);
        padding: 0 0;
    }

    .bookingForm input, textarea {
        border-radius: 5px;
        padding: 1vh 1vw;
        font-size: clamp(0.8rem, 3.2vw, 3rem);
        width: 60%;
        border: 1px solid var(--primary);
    }

    .nailInfo a {
        font-size: clamp(0.8rem, 3.2vw, 3rem);
    }

    .booking h2 {
        font-size: 1.5rem;
    }

    .bookingFormButton {
        border-radius: 5px;
        padding: 1.3vh 7.5vw;
        font-size: clamp(1.4vw, 3.7vw, 4.5vw);
        max-width: 50vw;
        align-self: center;
        box-shadow: 0 5px #999;
    }

    .bookingFormButton:active {
        box-shadow: 0 2px #666;
    }

    .booknow {
        align-self: flex-start;
        margin: 2vh 0;
    }

    .link {
        padding: 0 1rem;
        width: 40vw;
    }

    .link img {
        width: 5vw;
        height: 5vw;
    }

    .link h3 {
        font-size: 1rem;
    }

    .bookingWrapper {
        flex-direction: column;
    }

    .bookingInfoInput {
        margin: 3vh 0 0 0;
        flex: 1;
        display: flex;
        width: 100%;
    }

    .bookBtn {
        max-height: 3rem;
        margin: 2rem auto 0 auto;
    }
}
