.introHeader {
  display: flex;
  min-height: 18rem;
  justify-content: space-between;
  padding: 0 clamp(0rem, 16vw, 20rem);
  position: relative;
}

.introHeader h4 {
  color: #000;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;  font-weight: lighter;
  padding: 8px 0;
  word-spacing: 0;
  margin: 0 1.8vw 0 0;
  cursor: pointer;
  font-size: 1.2rem;
}

.introHeader h4:hover {
  color: var(--red-color);
  transition: color 0.5s ease-in-out;
  word-spacing: 1px;
}

.introHeader h3 {
  color: #000;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-weight: lighter;
  padding: 0 0;
  word-spacing: 0;
  /* cursor: pointer; */
  white-space: pre-wrap;
  font-size: 16px;
}

.introHeader h2 {
  font-weight: bold;
  color: var(--red-color);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-weight: lighter;
  padding: 0 0;
  word-spacing: 0;
  margin: 0 0 2vh 0;
  /* cursor: pointer; */
  white-space: pre-wrap;
  font-family: Rumble_Brave, sans-serif;
  font-size: 2rem;
}

.headerIntroContainer {
  margin: 6vh 0 0 0;
}

.introLogo {
  width: clamp(2rem, 5vw, 3rem);
  height: clamp(2rem, 5vw, 3rem);
  object-fit: cover;
}

.introHeader button {
  background-color: transparent;
  border: 0;
}

.menuItem {
  display: flex;
  flex-direction: row;
  margin: 1vw;
  z-index: 1;
  height: 10%;
}

.introHeader h4.selectedText {
  background-color: var(--primary);
  color: #ffffff;
  padding: 8px;
  text-shadow:none;
  font-weight: bold;
}

.headerBannerImg {
  width: 60%;
  max-height: 18rem;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-top-left-radius: 10vw;
  border-bottom-left-radius: 28vw;
}

.bookNow {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.bookNowA {
  margin-right: 1rem;
  color: var(--primary);
}

.clickableLogo {
  cursor: pointer;
}

/*SIZE FOR SMALL DEVICES*/
@media (max-width: 768px) {
  .introHeader {
    padding: 2vh 4vw;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: column;
    min-height: 18rem;
  }

  .introHeader h4 {
    color: #ffffff;
    font-weight: lighter;
    padding: 0 0;
    word-spacing: 0;
    margin: 0 0;
    cursor: pointer;
    text-align: center;
    font-size: clamp(1rem, 1.4vw, 1.8rem);
  }

  .introHeader h3 {
    font-size: 16px;
    text-align: left;
  }

  .introHeader h2 {
    text-align: left;
    font-size: 20px;
  }

  .introLogo {
    width: 11vw;
    height: 11vw;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .bookNowA {
    display: none;
  }

  .bookNowBtnHeader {
    padding: 0 !important;
    min-height: 2rem !important;
    min-width: 5rem !important;
  }

  .headerIntroContainer {
    margin: 4rem 0 0 2rem;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .headerBannerImg {
    width: 66%;
    min-width: 19rem;
    border-top-left-radius: 14vw;
    border-bottom-left-radius: 50vw;
    min-height: 18rem;
    object-fit: cover;
  }
}
