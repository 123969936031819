.bookNowBtn {
  background-color: var(--red-color);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 1vh 1vw;
  width: 12vw;
  min-height: 2.8rem;
  min-width: 5rem;
  transition: background-color 0.3s ease-in-out;
  flex-direction: row;
  display: flex;
  border-radius: 6px;
}

.bookNowBtn:hover h3.change-color-on-hover {
  color: var(--red-color);
  transition: color 0.3s ease-in-out;
}

.bookNowBtn:hover {
  background-color: var(--light-yellow-color);
  cursor: pointer;
}

.bookNowBtn:hover h3 {
  color: var(--background-color);
  transition: color 0.2s ease-in-out;
}

.bookNowBtn {
  h3 {
    color: #fff;
    margin: 0 0 0 0;
    font-size: small;
    font-weight: bold;
    text-align: center;
  }
}

.bookNowBtn img {
  width: 1.5vw;
  height: 1.5vw;
  transform: rotate(180deg);
  margin: 0 0 0 1rem;
}

.button {
  background-color: transparent;
  padding: 0;
  border: 0;
}

@media (max-width: 768px) {
  .bookNowBtn {
    min-height: 3rem;
    min-width: 10rem;
    padding: 0 2vw;
  }

  .bookNowBtn {
    h3 {
      margin: 0;
    }
  }

  .bookNowBtn img {
    width: 3vw;
    height: 3vw;
  }
}
