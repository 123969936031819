/* MobileHeader.module.css */
.mobileHeader {
  position: relative;
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary);
}

.dropdownContent {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(122, 103, 103, 0.4);
  z-index: 1;
  opacity: 0;
  pointer-events: none; /* Prevent interactions when closed */
}

/* Adjust the opacity when the menu is open */
.mobileHeader.open .dropdownContent {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease-in-out;
}

ul {
  list-style: none;
  padding: 1vh 1.5vw;
  margin: 0.5vh 0;
}

li {
  padding: 10px;
  color: var(--primary);
  cursor: pointer;
  font-size: 5vw;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.3s 0.3s, height 0.3s 0.3s, opacity 0.3s;
}

/* .fadeIn {
  opacity: 1;
  width: auto;
  height: auto;
  transition: width 0.3s, height 0.3s, opacity 0.3s 0.3s;
} */

@media (max-width: 768px) {
  .menuButton {
    font-size: 4vw;
    font-weight: 600;
  }

  .mobileHeader {
    position: absolute;
  }
}
