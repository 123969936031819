.imageIcon {
  width: 4rem;
  height: 4rem;
  position: fixed;
  bottom: 2vh;
  right: 2vw;
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both ;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-delay: 5s; /* Delay of 9.2s to make the total cycle 10s */
}

@keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

@media (max-width: 768px) {
  .imageIcon {
    width: 3.2rem;
    height: 3.2rem;
    bottom: 3vh;
  }
}
